
.meGrid{
  margin-top: -20rem;
}

.avatarWrapper{
  text-align: center;
}

.avatar {
  height: 15.5rem;
  width: 15.5rem;
  border-radius:5%;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.personalInfoWrapper{
  text-align: center;
}

.hello{
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 2.1px !important;
  font-weight: normal !important;
  margin-bottom: 1rem !important;
}

.nameSurname{
  font-size: 3rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem !important;
  font-family: "Heebo", sans-serif !important;
  font-weight: bold !important;
}

.profession{
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 1.5rem !important;
}

.socialSection{
  height: 5.7rem;
  background-color: #134f5c;
}

.socialContainer{
  padding-left: 9%;
}

.socialIcon{
  color: white;
}

.basicInfoSection{
  margin-top: 2rem;
}

.basicInfo {
  font-size: 16px !important;
  color: #777777;
  margin-bottom: 1rem!important;
}
