* {
  box-sizing: border-box;
}

body, html {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#root {
  height: 100%;
  flex-direction: column;
  display: flex;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #efefef;
}

.skillbar-percent {
  display: none
}

.spinner{
  margin-left: 48%;
  margin-top: 30%;
}