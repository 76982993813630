.timelineGrid {
  background-color: #efefef;
  margin-top: 2rem !important;
  margin-bottom: 5rem !important;
}

.timelineTitle{
    font-size: 1rem !important;
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 2.1px !important;
    font-weight: normal !important;
    margin-bottom: -1rem !important;
}

html, body {
    overflow-x:hidden
}