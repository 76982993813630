.topSection{
  background-color: #efefef;
}

.backgroundImage{
  background-image: url("top_background.jpg");
  background-size: 100%;
  height: 30rem;
}

.aboutMeGrid{
  margin-top: 4rem;
}

.aboutMe{
  font-size: 3rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem !important;
  font-family: "Heebo", sans-serif !important;
  font-weight: bold !important;
}

.description {
  font-size: 16px !important;
  color: #777777;
  margin-top: 2rem!important;
  margin-bottom: 1rem!important;
}